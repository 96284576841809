export default ({ app }) => {
    if (process.client) {
      window.addEventListener('storage', (event) => {
        console.log('登录状态已变更，您将被登出。');
        if (event.key === 'loginToken') {
          // 检测到登录令牌变化
          console.log('登录状态已变更，您将被登出。');
  
          // 调用 Nuxt.js 的 $auth 模块进行登出操作，或者重置状态
          // 如果你使用了 @nuxtjs/auth 模块
          // app.$auth.logout()
  
          // 或者，如果你有自定义的登出逻辑
          // 清除本地存储、状态等
          // localStorage.removeItem('isLoggedIn');
          // localStorage.removeItem('loginToken');
  
          // 重定向到登录页面或其他页面
        //   window.location.href = '/login';
        }
      });
    }
  };