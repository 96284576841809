export const appHead = {"meta":[{"charset":"utf-8"},{"httpEquiv":"Content-Type","content":"text/html;charset=utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, shrink-to-fit=no"}],"link":[{"rel":"stylesheet","href":"//at.alicdn.com/t/font_3161429_rjm4nb6jgk.css"},{"rel":"stylesheet","href":"https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css","integrity":"sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N","crossorigin":"anonymous"}],"style":[],"script":[{"src":"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js","integrity":"sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj","crossorigin":"anonymous"},{"src":"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js","integrity":"sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN","crossorigin":"anonymous"},{"src":"https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/js/bootstrap.min.js","crossorigin":"anonymous"},{"src":"/tracking/tracking.js"},{"src":"/tracking/data/face.js"},{"src":"/tracking/data/eye.js"},{"src":"/wangEditor.min.js"}],"noscript":[],"title":""}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = false

export const devPagesDir = null

export const devRootDir = null