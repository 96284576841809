import plugin_vue3_A0OWXRrUgq from "F:/web/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "F:/web/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "F:/web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "F:/web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "F:/web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "F:/web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import ssr_plugin_B4ptqVdIfe from "F:/web/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import auth_listener_client_GmAJa92k7S from "F:/web/plugins/auth-listener.client.js";
import element_plus_elM4AXquKi from "F:/web/plugins/element-plus.js";
import global_dY5SAi3BsR from "F:/web/plugins/global.js";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  ssr_plugin_B4ptqVdIfe,
  auth_listener_client_GmAJa92k7S,
  element_plus_elM4AXquKi,
  global_dY5SAi3BsR
]